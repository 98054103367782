<template>
  <section class="page-content">
    <h1>Hjelp</h1>
    <row container :gutter="20">
      <column :xs="12" :md="12" :lg="6">
        <Accordion>
          <template v-slot:title>
            Koster det noe å benytte Hytta.io?
          </template>
          <template v-slot:content>
            <p>
              Applikasjonen er gratis for alle
            </p>
          </template>
        </Accordion>
        <Accordion>
          <template v-slot:title>
            Hvordan registrere seg?
          </template>
          <template v-slot:content>
            <p>
              Trykk på <a href="#" @click="SignIn()">registrer</a> og lag deg en bruker.
            </p>
          </template>
        </Accordion>
      </column>
      <column :xs="12" :md="12" :lg="6">
        <Accordion>
          <template v-slot:title>
            Hvordan legger jeg til nye hytter?
          </template>
          <template v-slot:content>
            <p>Dersom du eier en hytte som du vil tilgjengeliggjøre og dele med andre må du
            opprette en hytte, da blir du automatisk administrator på Hytta. Deretter kan du
            gi tilgang til hytten til andre, samt opprette nye hytter som kan deles</p>
          </template>
        </Accordion>
      </column>
    </row>
  </section>
</template>
<script>
import Accordion from '@/components/Accordion';

export default {
  components: {
    Accordion
  },
  methods: {
    async SignIn() {
      await this.$auth
        .login()
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.error(`Error during authentication: ${error}`);
        });
    }
  }
}
</script>
