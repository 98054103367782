<template>
  <div class="accordion">
    <button
      @click="toggleAccordion()"
      class="accordion-title"
      :aria-expanded="isOpen"
    >
      <h2><slot name="title" /></h2>
      <UpArrow v-if="isOpen" />
      <DownArrow v-if="!isOpen" />
    </button>
    <div class="accordion-content" v-show="isOpen">
      <slot name="content" />
    </div>
  </div>
</template>
<script>
import UpArrow from '@/assets/upArrowOval.svg';
import DownArrow from '@/assets/downArrowOval.svg';

export default {
  name: 'Accordion',
  components: {
    UpArrow,
    DownArrow
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
    },
  },
}
</script>
<style lang="scss" scoped>
  .accordion {
    background-color: #E4EBE9;
    border-radius: 14px;
    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 1rem;
      background-color: inherit;
      border-radius: inherit;
      cursor: pointer;
      h2 {
        margin-bottom: 0;
        font-weight: 500;
      }

    }
    &-content {
      padding: 0 1rem 1rem 1rem;
      p {
        font-size: 20px;
      }
    }
  }
  .accordion:not(:last-child) {
    margin-bottom: 1rem;
  }
</style>
